import * as React from "react";
import { Button, Divider, Grid, Header, List, Image, Container, Segment, Icon } from "semantic-ui-react";
import {withLayout} from "../components/Layout";
import car from "../images/car.jpg";

const ClientsPage = () => {
  return (
    <Container>
      <Segment style={{ padding: "4em 0em" }} vertical>
      <Header as="h3" style={{ fontSize: "2em" }}>
        Billing Phone Numbers
      </Header>
        <Grid columns="equal" stackable>
          <Grid.Row>
            <Grid.Column>
                <h3>Personal Lines</h3>
                <p>
                  FACT 1-888-685-5740 <br />
                  Farmers 1-800-597-2548 <br />
                  Foremost 1-800-527-3905 <br />
                  Harleysville 1-800-338-8301 <br />
                  Ohio Casualty 1-800-843-6446 <br />
                  Progressive 1-877-776-2436 <br />
                  Safeco 1-877-566-6001 <br />
                  <br />
                </p>
            </Grid.Column>
            <Grid.Column>
                <h3>Commercial Lines</h3>
                <p>
                  Harleysville 1-800-338-8301 <br />
                  Maryland 1-800-332-6641 <br />
                  Ohio Casualty 1-800-843-6446 <br />
                  Progressive 1-800-444-4487 <br />
                  Safeco 1-877-566-6001 <br />
                  Technology 1-888-629-1420    <br />      
                </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid stackable verticalAlign="middle">
          <Header as="h3" style={{ fontSize: "2em" }}>
            Business Hours
          </Header>
          <Grid.Row>
            <Grid.Column>
              <p style={{ fontSize: "1.33em" }}>
                Monday-Friday <br />8:30am - 4:30 pm EST     
              </p>
            </Grid.Column>
          </Grid.Row>
          <Header as="h3" style={{ fontSize: "2em" }}>
            Contact Information
          </Header>
          <Grid.Row>
          <Grid.Column width={8}>
              <h3>Address</h3>
              <p style={{ fontSize: "1.33em" }}>
                Hurst-Weiss Insurance <br />
                <a style={{color: "inherit"}} href="https://www.google.com/maps/place/1653+McFarland+Rd,+Pittsburgh,+PA+15216/@40.394406,-80.046807,13z/data=!4m5!3m4!1s0x8834f70ec15a92e7:0x845976f1bc9bdd75!8m2!3d40.3944059!4d-80.0468068?hl=en-US">
                1653 McFarland Road <br />
                Pittsburgh, PA  15216
                </a>         
              </p>
              <h3>Phone</h3>
              <p>
                412-922-8222           
              </p>
              <h3>Email</h3>
              <p>
              <a href="mailto:info@hurstweiss.com">info@hurstweiss.com</a>           
              </p>
              <h3>Fax</h3>
              <p>
                412-922-1588
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={8}>
              <div className="mapouter"><div className="gmap_canvas"><iframe width={400} height={400} id="gmap_canvas" src="https://maps.google.com/maps?q=1653%20McFarland%20Road%20Pittsburgh%2C%20PA&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} /><a href="https://www.embedgooglemap.net" /></div><style dangerouslySetInnerHTML={{__html: ".mapouter{text-align:right;height:400px;width:400px;}.gmap_canvas {overflow:hidden;background:none!important;height:400px;width:400px;}" }} /></div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

export default withLayout(ClientsPage);
